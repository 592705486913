<template>
  <div>
    <div
      class="d-flex justify-content-center align-items-center flex-column mt-5"
    >
      <b-row class="w-100 justify-content-center">
        <b-col cols="10" md="8">
          <img
            src="@/assets/img/confirm-new-franchise.jpg"
            alt="confirm-illustration"
            class="w-100"
          />
        </b-col>
      </b-row>
      <h2 class="h3 mt-4">Félicitations !</h2>
      <p class="h5 text-center w-75 my-3">
        Votre espace a été créé avec succès, vous pouvez dès maintenant écouter
        votre radio et la personnaliser à votre goût.
      </p>
      <p>
        Voici vos identifiants d’accès à myHubcast. Conservez-les précieusement
        :
      </p>
      <div class="logs-infos mb-3">
        <div><span>Identifiant : </span>{{ userLogs.login }}</div>
        <div>
          <span>Mot de passe : </span
          >{{ passwordShow ? userLogs.password : passwordStr }}
          <span class="ml-2 cursor-pointer" @click="showPassword"
            ><b-icon :icon="passwordShow ? 'eye-slash' : 'eye'"></b-icon
          ></span>
        </div>
      </div>
      <h-button @click="logUser()" green size="md" class="mt-3 mb-3 ">
        {{
          connexionLoading
            ? 'Chargement...'
            : 'Accéder à myHubcast et écouter ma radio'
        }}
      </h-button>
    </div>
  </div>
</template>

<script>
import userService from '@/services/userService'

import { mapState, mapGetters } from 'vuex'
export default {
  data() {
    return {
      passwordShow: false,
      passwordStr: '',
      connexionLoading: false,
    }
  },
  methods: {
    showPassword() {
      this.passwordShow = !this.passwordShow
      if (this.passwordShow) this.password = 'loremipsum'
      else this.password = this.passwordStr
    },
    async logUser() {
      this.connexionLoading = true
      const credentials = {
        login: this.userLogs.login,
        password: this.userLogs.password,
      }
      try {
        const res = await userService.logUser(credentials)
        this.$store.dispatch('user/_setUser', res.data)
        this.checkAutorizationAndPush()
      } catch (err) {
        this.$toast.error('Identifiant ou mot de passe incorrect')
        this.connexionLoading = false
      } finally {
        this.connexionLoading = false
      }
    },
    checkAutorizationAndPush() {
      if (this.authorizedRoleUser) {
        if (this.isSingleSite)
          this.$router.push({
            name: 'player',
            params: { id: this.singleVisibieSite },
          })
        else this.$router.push({ name: 'players' })
        this.$toast.clear()
        window.scrollTo(0, 0)
      } else {
        this.$toast.warning(
          'Vous ne possedez pas le role nécessaire pour accéder à cette application'
        )
        this.connexionLoading = false
        this.formValid = false
        this.changeValue = false
      }
    },
  },
  computed: {
    ...mapState({
      userLogs: (state) => state.campaign.userLogs,
    }),
    ...mapGetters({
      authorizedRoleUser: 'user/authorizedRoleUser',
      isSingleSite: 'user/isSingleSite',
      singleVisibieSite: 'user/singleVisibieSite',
    }),
  },
  created() {
    let str = ''
    for (let i = 0; i < this.userLogs.password.length; i++) {
      str += '*'
    }
    this.passwordStr = str
  },
}
</script>

<style lang="scss" scoped>
p {
  line-height: 30px;
}
.logs-infos {
  padding-left: 20px;
  border-left: 2px solid $primary;
  span {
    font-weight: 600;
  }
}
img {
  min-height: 150px;
}
</style>
