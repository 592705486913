<template>
  <div class="steps">
    <div
      v-for="i in steps"
      :key="i"
      class="step"
      :class="{ 'active-step': i - 1 <= currentStep }"
    ></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      steps: 4, //0, 1, 2, 3
    }
  },
  props: {
    currentStep: Number,
  },
}
</script>

<style lang="scss" scoped>
.steps {
  margin: -20px -20px 0 -20px;
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 6px;
  .step {
    background: #e3e5ea;
    height: 4px;
    border-radius: 10px;
    &.active-step {
      background: #f03c1f;
    }
  }
}
</style>
