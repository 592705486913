<template>
  <div>
    <h4 class="h4 mb-2">
      {{ siteResults.length }} points de vente ont été trouvés
    </h4>
    <span
      >Veuillez sélectionner votre magasin dans la liste ci-dessous pour
      continuer</span
    >
    <div class="mt-4">
      <b-row class="align-items-center mb-2">
        <b-col cols="1"> </b-col>
        <b-col class="font-weight-bold">Nom du point de vente</b-col>
        <b-col class="font-weight-bold">Adresse</b-col>
        <b-col class="font-weight-bold">Nom du gérant</b-col>
      </b-row>
      <b-row
        class="row-pos align-items-center cursor-pointer"
        v-for="siteResult in siteResults"
        :key="siteResult.idSite"
        @click="selectedSiteId = siteResult.idSite"
      >
        <b-col cols="1" class="d-flex justify-content-center">
          <b-form-radio
            v-model="selectedSiteId"
            name="selected-site"
            :value="siteResult.idSite"
            class="ml-2"
          ></b-form-radio>
          <!-- <b-form-checkbox
            v-model="siteResult.select"
            name="checkbox"
            :value="true"
            class="ml-1"
          >
          </b-form-checkbox> -->
        </b-col>
        <b-col>{{ siteResult.name ? siteResult.name : '-' }}</b-col>
        <b-col>{{ siteResult.address1 ? siteResult.address1 : '-' }}</b-col>
        <b-col>{{ siteResult.siteOwner ? siteResult.siteOwner : '-' }}</b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      status: false,

      selectedSiteId: null,
    }
  },
  props: {
    siteResults: Array,
  },
  watch: {
    selectedSiteId() {
      this.$emit('selectSiteEvent', this.selectedSiteId)
    },
  },
}
</script>

<style lang="scss" scoped>
.row-pos {
  min-height: 50px;
  &:hover {
    background: #f8f9fa;
  }
}
</style>
