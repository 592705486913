<template>
  <div>
    <b-container class="container-register ">
      <b-navbar
        id="nav"
        variant="faded"
        type="light"
        class="mb-2 p-0 is-fixed-top shadow-none"
      >
        <b-navbar-brand class="py-0">
          <img
            class="mt-2"
            width="220px"
            src="@/assets/svg/myHubcast-logo.svg"
            alt="myHybcast logo"
          />
        </b-navbar-brand>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto align-items-center">
          <img
            v-if="logoSrc"
            class="brand-logo"
            :src="logoSrc"
            alt="logo brand"
          />
        </b-navbar-nav>
      </b-navbar>
      <h-card>
        <StepsIndicator :currentStep="currentStep"></StepsIndicator>
        <div class="overflow-hidden">
          <transition :name="animation" mode="out-in">
            <component
              :is="currentStepComponent"
              @prevStep="prevStep()"
              @nextStep="nextStep()"
            ></component>
          </transition>
        </div>
      </h-card>
    </b-container>
  </div>
</template>

<script>
import RegisterWelcome from '@/components/register/RegisterWelcome'
import RegisterSiteInformations from '@/components/register/RegisterSiteInformations'
import RegisterAccount from '@/components/register/RegisterAccount'
import RegisterConfirmation from '@/components/register/RegisterConfirmation'
import StepsIndicator from '@/components/register/StepsIndicator'
export default {
  data() {
    return {
      stepsComponents: [
        RegisterWelcome,
        RegisterSiteInformations,
        RegisterAccount,
        RegisterConfirmation,
      ],
      currentStep: 0,
      animation: 'component-next',
    }
  },
  methods: {
    prevStep() {
      this.animation = 'component-prev'
      this.currentStep--
    },
    nextStep() {
      this.animation = 'component-next'
      this.currentStep++
    },
  },
  computed: {
    logoSrc() {
      return this.$store.getters['campaign/logoSrc']
    },
    currentStepComponent() {
      return this.stepsComponents[this.currentStep]
    },
  },
  components: {
    StepsIndicator,
  },
}
</script>

<style lang="scss" scoped>
.brand-logo {
  max-width: 230px;
  max-height: 50px;
  width: auto;
  height: auto;
}
.component-next-enter-active,
.component-prev-enter-active,
.component-next-leave-active,
.component-prev-leave-active {
  transition: 0.2s ease;
}
.component-next-enter {
  opacity: 0;
  transform: translateX(400px);
}
.component-next-leave-to {
  opacity: 0;
  transform: translateX(-400px);
}
.component-prev-enter {
  opacity: 0;
  transform: translateX(-400px);
}
.component-prev-leave-to {
  opacity: 0;
  transform: translateX(+400px);
}

@media (min-width: 992px) {
  .container-register {
    max-width: 720px;
  }
  .brand-logo {
    max-width: 230px;
    max-height: 70px;
    width: auto;
    height: auto;
  }
}
@media (min-width: 1200px) {
  .container-register {
    max-width: 900px;
  }
}
</style>
