<template>
  <div>
    <div class="d-flex justify-content-center flex-column mt-5">
      <h2 class="h3">Confirmation de votre point de vente</h2>
    </div>
    <b-alert show variant="success" class="success mt-3 py-2 px-4">
      <h5 class="h5 font-weight-semi-bold">
        <b-icon icon="check-circle" class="mr-2"></b-icon>
        Votre point de vente a été identifié !
      </h5>
      <p>
        Nous avons trouvé les informations de votre point de vente, veuillez
        vérifier et corriger les données ci-dessous.
      </p>
    </b-alert>
    <div v-if="siteInfosEdit">
      <b-row class="mt-3">
        <b-col md="6">
          <h-input
            disabled
            v-model.trim="siteInfosEdit.custReferences"
            :value-prop="siteInfosEdit.custReferences"
            textLight
            label="Référence du point de vente"
          ></h-input>
        </b-col>
        <b-col md="6">
          <h-input
            disabled
            textLight
            v-model.trim="siteInfosEdit.name"
            :value-prop="siteInfosEdit.name"
            label="Nom du point de vente"
          ></h-input>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col md="12">
          <h-input
            textLight
            v-model="siteInfosEdit.address1"
            :value-prop="siteInfosEdit.address1"
            label="Adresse postale"
          ></h-input>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col md="12">
          <h-input
            textLight
            v-model="siteInfosEdit.address2"
            :value-prop="siteInfosEdit.address2"
            label="Complément d'adresse"
          ></h-input>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col md="6">
          <h-input
            textLight
            v-model.trim="siteInfosEdit.postCode"
            :value-prop="siteInfosEdit.postCode"
            label="Code postal"
          ></h-input>
        </b-col>
        <b-col md="6">
          <h-input
            textLight
            v-model.trim="siteInfosEdit.city"
            :value-prop="siteInfosEdit.city"
            label="Ville"
          ></h-input>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col md="6">
          <h-input
            textLight
            v-model.trim="siteInfosEdit.siteOwner"
            :value-prop="siteInfosEdit.siteOwner"
            label="Nom du responsable"
          ></h-input>
        </b-col>
        <b-col md="6">
          <h-input
            v-model="siteInfosEdit.telPro"
            :value-prop="siteInfosEdit.telPro"
            textLight
            label="Numéro de téléphone"
          ></h-input>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col md="12">
          <h-input
            textLight
            v-model.trim="siteInfosEdit.email"
            :errorState="$v.siteInfosEdit.email.$error"
            :value-prop="siteInfosEdit.email"
            error-feedback="Adresse email incorrecte"
            label="Email"
          ></h-input>
        </b-col>
      </b-row>
    </div>
    <b-row class="mt-3">
      <b-col md="6">
        <h-button @click="prevStep()" grey outline size="md" class="my-3 ">
          <b-icon icon="arrow-left-short" scale="1.3" class="mr-1"></b-icon>
          Etape précédente
        </h-button>
      </b-col>
      <b-col md="6" class="d-flex justify-content-end">
        <h-button @click="editPOS()" green size="md" class="mt-md-3 mb-3">
          Confirmer mes informations
          <b-icon
            icon="arrow-right-short"
            scale="1.3"
            class="ml-1"
          ></b-icon> </h-button
      ></b-col>
    </b-row>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import campaignService from '@/services/campaignService'
export default {
  data() {
    return {
      siteInfosEdit: null,
      siteEdited: false,
    }
  },
  validations: {
    siteInfosEdit: {
      email: { required, email },
    },
  },
  methods: {
    prevStep() {
      this.$emit('prevStep')
    },
    async editPOS() {
      this.$v.$touch()
      if (!this.siteEdited && !this.$v.$invalid) {
        this.$emit('nextStep')
        return
      }

      if (!this.$v.$invalid) {
        try {
          campaignService.editSite(this.siteInfosEdit)
          this.$store.commit(
            'campaign/REPLACE_SITE_AFTER_EDITION',
            this.siteInfosEdit
          )
          this.$emit('nextStep')
        } catch {
          // eslint-disable-next-line no-console
          console.error('error')
        }
      }
    },
  },
  watch: {
    siteInfosEdit: {
      handler: function() {
        this.siteEdited = true
      },
      deep: true,
    },
  },
  computed: {
    siteInfos() {
      return this.$store.state?.campaign?.campaignSite
    },
  },
  mounted() {
    this.siteInfosEdit = JSON.parse(JSON.stringify(this.siteInfos))
    setTimeout(() => {
      this.siteEdited = false
    }, 200)
  },
}
</script>

<style lang="scss" scoped>
button {
  width: 100%;
}

.success {
  background: #f0fff4;
  border: 0;
  border-top: 4px solid #38a169;
  h5,
  p {
    color: #38a169;
  }
}
.error-label {
  color: red;
  font-size: 0.8rem;
}

@media (min-width: 992px) {
  button {
    width: auto;
  }
}
</style>
