<template>
  <div>
    <div class="d-flex justify-content-center flex-column mt-4">
      <section class="welcome p-4 mb-3 w-100">
        <h2 class="h3">Bienvenue sur myHubcast</h2>
        <p>{{ subscriptionMessage }}</p>
      </section>
      <b-row class="mt-3">
        <b-col md="6">
          <h-input
            v-model="posReference"
            v-on:keyup.enter="getSiteInfo"
            textLight
            label="Référence du point de vente"
          ></h-input>
        </b-col>
        <b-col md="6" class="mt-2 mt-md-0">
          <h-input
            v-model="posCp"
            v-on:keyup.enter="getSiteInfo"
            textLight
            label="Code postal"
          ></h-input>
        </b-col>
      </b-row>
    </div>

    <div class="d-flex justify-content-end mt-3">
      <h-button
        :disabled="searchDisabled"
        @click="getSiteInfo()"
        purple
        size="md"
        class="mt-3 mb-3 "
      >
        <template v-if="searchSiteLoading">
          Recherche en cours...
        </template>
        <template v-else>Rechercher mon point de vente </template>
      </h-button>
    </div>
    <RegisterWelcomeSelectPOS
      v-if="siteResultsSelection && !showError"
      :siteResults="siteResultsSelection"
      @selectSiteEvent="setSelectedSite"
    />
    <div
      v-if="seachSiteResults && !showError"
      class="d-flex justify-content-end mt-3"
    >
      <h-button
        :disabled="!selectedSiteId"
        @click="saveSelectedSite()"
        green
        size="md"
        class="mt-3 mb-3 "
      >
        Confirmer mon point de vente
        <b-icon icon="arrow-right-short" scale="1.3" class="ml-1"></b-icon>
      </h-button>
    </div>
    <b-alert v-if="showError" show variant="info" class="info mt-3 py-4 px-4">
      Aucun point de vente n'a été trouvé à partir de votre recherche, veuillez
      réessayer ou contactez le support technique au 04 67 15 58 59.
    </b-alert>
  </div>
</template>

<script>
import campaignService from '@/services/campaignService'
import RegisterWelcomeSelectPOS from '@/components/register/RegisterWelcomeSelectPOS'
export default {
  data() {
    return {
      dataLoaded: false,
      posReference: '',
      posCp: '',
      searchSiteLoading: false,
      seachSiteResults: null,
      showError: false,
      selectedSiteId: null,
    }
  },
  components: {
    RegisterWelcomeSelectPOS,
  },
  async created() {
    const campaignCode = this.$route.params.code
    if (!campaignCode) this.$router.push({ name: 'login' })

    try {
      const currentCampaign = await campaignService.getBrandCampaign(
        campaignCode
      )
      this.$store.commit('campaign/SET_CAMPAIGN', currentCampaign.data)

      const brandLogo = await campaignService.getBrandLogo(
        currentCampaign.data.idBrand
      )
      this.$store.commit('campaign/SET_BRAND_LOGO', brandLogo.data)

      const brandInfos = await campaignService.getBrandInfo(
        currentCampaign.data.idBrand
      )
      this.$store.commit('campaign/SET_BRAND_INFO', brandInfos.data)
      this.dataLoaded = true
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err)
    }
  },
  computed: {
    siteResultsSelection() {
      const seachSiteResultsComp = this.seachSiteResults?.map((obj) => ({
        ...obj,
        selected: false,
      }))
      return seachSiteResultsComp
    },

    subscriptionMessage() {
      return this.$store.state?.campaign?.campaign?.subscriptionMessage || ''
    },
    brandName() {
      return this.$store.state?.campaign?.brand?.name || ''
    },
    brandId() {
      return this.$store.state?.campaign?.brand?.id
    },
    searchDisabled() {
      return (
        this.searchSiteLoading ||
        !this.dataLoaded ||
        !this.posCp ||
        !this.posReference
      )
    },
  },
  methods: {
    async getSiteInfo() {
      if (this.searchDisabled) return
      try {
        this.searchSiteLoading = true
        let params = {}
        if (this.posCp) params.cp = this.posCp
        if (this.posReference) params.reference = this.posReference
        const results = await campaignService.searchSite(this.brandId, params)
        const audioSitesOnly = results.data.filter((e) => e.offreType === 1)
        this.showError = false
        if (audioSitesOnly.length === 0) {
          this.seachSiteResults = null
          this.showError = true
          return
        }
        if (audioSitesOnly.length === 1) {
          this.$store.commit('campaign/SET_CAMPAIGN_SITE', audioSitesOnly[0])
          this.$emit('nextStep')
        } else {
          this.seachSiteResults = audioSitesOnly
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        if (e.response.status === 404) this.showError = true
      } finally {
        this.searchSiteLoading = false
      }
    },
    setSelectedSite(id) {
      this.selectedSiteId = id
    },
    saveSelectedSite() {
      const selectedSite = this.seachSiteResults.find(
        (e) => e.idSite === this.selectedSiteId
      )
      this.$store.commit('campaign/SET_CAMPAIGN_SITE', selectedSite)
      this.$emit('nextStep')
    },
  },
}
</script>

<style lang="scss" scoped>
.welcome {
  border-radius: 6px;
  background: #fafafa;
  border: 1px solid #f2f2f2;
  p {
    color: #737e96;
  }
}
.info {
  border: 0;
  border-top: 4px solid #869bff;
  color: #526fff;
}
</style>
