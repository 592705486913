import client from './axios.js'

export default {
  getBrandCampaign(campaignCode) {
    return client.get(
      `/brand/subscriptioncampaign/unauthorized/${campaignCode}`
    )
  },
  getBrandLogo(idBrand) {
    return client.get(`/brand/unauthorized/${idBrand}/logo`)
  },
  getBrandInfo(idBrand) {
    return client.get(`/brand/unauthorized/detail/${idBrand}`)
  },
  searchSite(brandId, params) {
    return client.get(`/brand/${brandId}/unauthorized/site`, { params })
  },
  editSite(params) {
    return client.post(`/site/unauthorized`, params)
  },
  createUser(params, login, idSite) {
    return client.post(`/user/unauthorized/${login}?idSite=${idSite}`, params)
  },
  createLogin(brandName, siteName) {
    return client.get(
      `/user/unauthorized/login/generation?brandname=${brandName}&sitename=${siteName}`
    )
  },
  editCampaign(params) {
    return client.post(
      `/site/${params.IdSite}/unauthorized/campaignsite`,
      params
    )
  },
  getSiteCampaign(idBrand, idSite) {
    return client.get(
      `/brand/${idBrand}/unauthorized/subscriptioncampaign/site/${idSite}`
    )
  },
}
